import react , {useEffect, useState } from 'react';
import axios from 'axios';

export default function GeoLocation({defaultCenter, onPositionChange}) {

    const defaultPosition = {                       // this is default location, if user has blocked geolocation
        coords: {location: { latitude: defaultCenter.location.lat, longitude: defaultCenter.location.lng }}
      };

      // this is used as center, if user location not found and no address selected
      const defaultAddress = defaultCenter;

      const [position, setPosition] = useState(defaultPosition);
      

    useEffect(() => {

        const geo = ("geolocation" in navigator);
        
          if (!geo) {
            // console.log("Navigator geolocation blocked");
            setPosition(defaultPosition)
            return;
          } 
            
          // console.log("geolocation enabled");
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setPosition(position) 
              console.log("Got position: ", position);      
            }, 
            (error) => {
              setPosition(defaultPosition);                   // user has denied fetching geolocation -> default position is used
              // console.log(error)
              }
          )
          
       }, [navigator])

    useEffect(() => {
        // get user address from google geolocation API
        async function getUserAddress () {
            try {
                const response = await axios.post(
                  'https://xn--retkell-cxa.fi/api/google.php',
                  {
                    lat: position.coords.latitude,
                    lon: position.coords.longitude,
                  },
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  },
                )
                // console.log("Response:" , response.data);
                if (response.data) {
                
                  // get user country
                 
                    const place = response.data.results[0];
                    // console.log("Geolocation data: ", res.data.results[0]);
                    // find country code
                    var country = '';
                    for (var i = 0; i < place.address_components.length; i++) {
                      for (var j = 0; j < place.address_components[i].types.length; j++) {
                        if (place.address_components[i].types[j] === "country") {
                        country = place.address_components[i].short_name;
                        }
                      }
                    }
                    if (country === "FI") {
                        // get user address
                        const userAddress = place.formatted_address;
                        // console.log("User's location: ", position, ",", userAddress)
                         onPositionChange({
                            location: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            },
                            address: userAddress,
                        })
                    } else {
                        // country is not Finland, use default address
                        onPositionChange (defaultAddress)
                    }  
                  
                } else {
                  // no result received on query
                  onPositionChange (defaultAddress)
                }
              } catch (err) {
                console.log(err);
                onPositionChange (defaultAddress)
              }
              
        }
        getUserAddress();
    }, [position])
    
 return null;    

}