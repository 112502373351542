import axios from 'axios';

const baseurl = 'https://lipas.fi/rest/sports-places/?fields=name&fields=type.typeCode&fields=location.city.name&fields=location.coordinates.wgs84&fields=www';

function getapiurl(pagenr, filters, center)  {
    const closeToDist = "&closeToDistanceKm=100"
    const typeCode1 = filters[0] ? "&typeCodes=4405" : "";
    const typeCode2 = filters[1] ? "&typeCodes=4402" : "";
    const typeCodes = typeCode1+typeCode2;
    const closeToLat = `&closeToLat=${center.lat}`;
    const closeToLon = `&closeToLon=${center.lng}`;
    const page = `&page=${pagenr}`;
   
    // console.log("using the following search url: ", baseurl + closeToLat + closeToLon + typeCodes );
    // console.log("Current center: ", currentcenter.lat, ",", currentcenter.lng);
    // console.log("Fetching with URL ", baseurl + closeToDist + page + closeToLat + closeToLon + typeCodes );
    return(baseurl + closeToDist + page + closeToLat + closeToLon + typeCodes);
  }

  export async function fetchData(filters, center) {
    const results = []
    for (let i = 1; i < 20; i++) {
      // console.log("fetching page ", i);
      const res = await axios.get(getapiurl(i, filters, center));
      if (res.data.length > 0) {
        // console.log("Got " , res.data.length, " records, pushing");
        // console.log("Trails: ", res.data);
        results.push(...res.data);
      } else {
        // console.log("page is empty, no more pages to fetch");
        break;
      }

    }
    // console.log("Fetch results:", results);
    return(results);
  }

  export async function searchTrails (searchString, filters) {
    // const apiUrl = "https://lipas.fi/rest/sports-places?&typeCodes=4405&typeCodes=4402&fields=name&fields=type.typeCode&fields=location.city.name&fields=location.coordinates.wgs84&searchString=" + searchString;
    const typeCode1 = filters[0] ? "&typeCodes=4405" : "";
      const typeCode2 = filters[1] ? "&typeCodes=4402" : "";
      const typeCodes = typeCode1+typeCode2;
    
    const apiUrl = baseurl + typeCodes + "&searchString=" + searchString
    // console.log ("searching with url: ", apiUrl);
    let results = [];
    try {
        const res = await axios.get(apiUrl);
        if (res.data.length > 0) {
            results = res.data;
        }
        
    } catch(err) {
        console.log("Search failed");
    }
    return (results);
  }

  export async function fetchTrailDetails (trailID) {
    const apiUrl = `https://lipas.fi/rest/sports-places/${trailID}`;
    let result = {
        trailtype: "",
        trailjson: []
    }
    try {
        const res = await axios.get(apiUrl);
        result = {
            trailtype: res.data.type.typeCode,
            trailjson: res.data.location.geometries
        }
    } catch {
        console.log ("Error in getting trail data");
    }
    return (result);
  }

