
import { Row, Col, Container } from 'react-bootstrap';
import { Header } from 'semantic-ui-react';
import TrailMap from '../maps/trailmap';

import 'gpxparser';
import './styles.css';

export default function UserTrail({file, details}) {

  const location = {lat: 61.48, lng: 23.78}; 
    
  let geojson = null;

  if (details === undefined) {
    return null
  }

  else {
    try {
      geojson = JSON.parse(details.geojson);
    }
    catch(err) {
      geojson = null;
    }
   // if ((details.geojson !== undefined) &&  (details.geojson !== null )) geojson = JSON.parse(details.geojson);

  return (
    <Container fluid className="routeModal">
      <Row>
        <Col >
        <div className="infobox">
          <Row>
              <Header as='h2'>{details.trailname}</Header>
          </Row>
          <Row>
              <Col xs="2"><Header as='h4'>Päivä:</Header></Col>
              <Col>{details.traildate}</Col> 
          </Row> 
          <Row>
              <Col xs="2"><Header as='h4'>Pituus: </Header> </Col>
              <Col>{details.distance} km <br /></Col>
          </Row>
          <Row>
              <Col xs="2"><Header as='h4'>Kokonaisnousu: </Header> </Col>
              <Col>{details.elevation} m</Col>
          </Row>
          <Row>
              <Col></Col>
          </Row>
        </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <TrailMap center = {location} gpxfile={file} gJSON={geojson} showElevation={true} />
          <div id="elevation-div"></div>
        </Col>
      </Row>
    </Container>
  );
}}
