import { WMSTileLayer, TileLayer, LayersControl } from 'react-leaflet';

const  getTileUrl = (contractType, layertype) => {
 
  if (contractType === "contract") {
    return ("https://mml.xn--retkell-cxa.fi/?z={z}&y={y}&x={x}&layer=" + layertype);
  }
  else {
    return ("https://xn--retkell-cxa.fi/api/?z={z}&y={y}&x={x}&layer=" + layertype);
  }
}

const MapLayersMML = (props) => {

    // get map layers by using open mml API with proxy
     
      const mmlBaseLayers = ["taustakartta", "maastokartta", "selkokartta", "ortokuva"];
      const defaultLayer = props.defaultLayer;

    return (
        <LayersControl position="topright">
  
            {mmlBaseLayers.map((layer, index) => (
                  <LayersControl.BaseLayer key={index} checked={layer===defaultLayer} name={layer}>
                      <TileLayer
                          attribution='&copy; Aineisto: Maanmittauslaitos'
                          url={getTileUrl("open", layer)}
                      />
                  </LayersControl.BaseLayer>
                  ))}
          </LayersControl>
    )
  }

  export const MapLayersMMLOpen = () => {
    return (
      <MapLayersMML defaultLayer="taustakartta" />
    )
  }

  export const MapLayersMMLOpenDetails = () => {
    return (
      <MapLayersMML defaultLayer="maastokartta" />
    )
  }

  export const MapLayersContract = () => {

    const mmlBaseLayers = ["taustakartta", "maastokartta", "selkokartta", "ortokuva"];
   
    return (
      <LayersControl position="topright">

          {mmlBaseLayers.map((layer, index) => (
                <LayersControl.BaseLayer key={index} checked={layer==="maastokartta"} name={layer}>
                    <TileLayer
                        attribution='&copy; Aineisto: Maanmittauslaitos'
                        url={getTileUrl("contract", layer)}
                    />
                </LayersControl.BaseLayer>
                ))}
          
          <LayersControl.BaseLayer name="Suunnistuskartta">
            <WMSTileLayer
              url="https://wmts.mapant.fi/wmts.php?z={z}&x={x}&y={y}"
              attribution='&copy;MapAnt | data provided by the <a href="http://www.maanmittauslaitos.fi/en" target="_blank">National Land Survey of Finland</a> under the <a href="https://creativecommons.org/licenses/by/4.0/legalcode">Creative Commons license</a>. '
              maxZoom= {19}
              minZoom= {0}
            />
          </LayersControl.BaseLayer>

         <LayersControl.Overlay name="Korkeusmalli" checked="true">         
            <TileLayer
              url={getTileUrl("contract", "korkeusmalli_vinovalo")}
              transparent ='true'
              opacity = '0.5'
            />
         </LayersControl.Overlay>
        </LayersControl>
    )
}

  