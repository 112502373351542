// this component shows the map of Finland.
// GPX trail and/or JSON trail given as parameters are shown on the map 

import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, useMap, Marker, Tooltip, useMapEvents, FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import "proj4leaflet";
import "leaflet-gpx";
import { MapLayersMMLOpen } from './maplayers';
import { markerIcon, userTrailMarkerIcon } from './markers';
import { LocationControl, ChangeCRS, crsFinland } from './mapcontrols';

let trailLayer = null;


const ShowHikingMap = ({
    center, 
    trailType, 
    gJSON, 
    trails, 
    usertrails, 
    highlighted, 
    fitbounds, 
    onButtonClicked, 
    onMouseOver, 
    onMouseOut,
    onUserTrailClicked,
    onCenterMoved}) => 
{
  const [map,setMap] = useState(null);  
  const [userTrailHighlighted, setUserTrailHighlighted] = useState(null);
  const featureGroupRef = useRef();

  useEffect(() => {
     if (!map) return;
     map.eachLayer(function (layer) {
       if ( layer.myTag &&  layer.myTag === "GeoJSON") {
         // console.log("Removing layer: ", layer)
         map.removeLayer(layer)
       }
     });
   }, [trails, usertrails]);

    useEffect(() => {
      if (!map) return;
      map.setView(center, map.getZoom(), {animation: true});
    }, [map, center])
  
    useEffect(() => {
      // reset zoom to default after search reset
      if (!map) return;
      if (!fitbounds) {
        map.setZoom(5);
      }
    }, [map, fitbounds]);

    useEffect(() => {
      // fit map to marker bounds
      if (!map) {
        // console.log("no map, returning");
        return;
      };
      
      if (!featureGroupRef) {
        // console.log("No Feature Group Reference");
        return;
      };
     
      if (fitbounds) {                          // fit markers to bounds if search is active
        if (trails.length > 0) {
          const bounds = featureGroupRef.current.getBounds();
          map.fitBounds(bounds);
          map.flyToBounds(bounds);
        }
      }
     
    }, [map, fitbounds, trails]);
  

    function ShowGeoJSON() {
      const map = useMap();
      try {
        // remove previous layers
          if (trailLayer) {
            map.removeLayer(trailLayer);  // remove the layers
          }
          
        if (gJSON) { 
          if (gJSON.features.length === 1 && gJSON.features[0].geometry.type === "Point") {
            // console.log ("This is point");
            return null;
          }
            
          const trailstyle = {color: "green"};
          const skistyle = {color: "red"};
          let style = trailstyle;

          if ((trailType === 4405) || (trailType === 'UserTrail')) 
            style = trailstyle;
          else 
            style = skistyle;

          // show geojson track on map
          trailLayer = L.geoJSON(gJSON, {
            style: style,
            onEachFeature: function (feature, layer) {
                // console.log("Drawing feature: ", feature);
                layer.myTag = "GeoJSON"
            }
          }).addTo(map);  
        } else 
        
        if (trailLayer) {
          // no gjson to draw, remove previous ones
          // map.removeLayer(trailLayer);  // remove the layers
        }
      } catch(err) {
        console.log("Error in adding JSON to map:", err);
      }
        
      return null
    } 

    function GetMapCenter() {
          useMapEvents({
            dragend: (e) => {
              onCenterMoved (e.target.getCenter());
            }
          });
          return null;
    }

    
    function handleMouseOver(id) {
      // console.log("Setting highligh to trail id: ", id)
      setUserTrailHighlighted(id);
      onMouseOver(id, "user");
    }

    function handleMouseOut () {
      setUserTrailHighlighted(null)
    }
    // Show map with GPX trail, GeoJSON and trail markers

    

    return (
      <div >
        
        <MapContainer
            style={{height: '90vh'}}
            center={center}
            continuousWorld= {true} 
            worldCopyJump= {false}
            scrollWheelZoom={false}
            crs = {crsFinland}
            zoom={5}
            ref = {setMap}
            maxZoom={11}
        >
               
        <MapLayersMMLOpen default="taustakertta"/>
        <LocationControl/>
        <GetMapCenter/>
        <ChangeCRS />
        
        <FeatureGroup ref = {featureGroupRef} >
          {
            (usertrails) && usertrails.map((trail) =>
              <Marker
                key = {trail.id}
                icon = {userTrailMarkerIcon(trail, userTrailHighlighted)}
                position={[
                  trail.latitude,
                  trail.longitude
                ]}
                eventHandlers={{
                  click: (e) => {
                    // console.log(e.target.options.data);  // will print trail name in console
                    onUserTrailClicked(trail.id);
                  },
                  mouseover: (e) => {
                    // console.log(e.target.options.data);  // will print trail name in console
                    handleMouseOver(trail.id);
                  },
                  mouseout: (e) => {
                    // console.log("Mouse out")
                    handleMouseOut();
                  }
                }}
              >
                <Tooltip>
                  {trail.trailname}
              </Tooltip>
              </Marker>
            )
          }
          {
            (trails) && trails.map((trail) => 
            <Marker 
            key={trail.sportsPlaceId}
            icon = {markerIcon(trail, highlighted)}
            position={[
                trail.location.coordinates.wgs84.lat,
                trail.location.coordinates.wgs84.lon
              ]}
              eventHandlers={{
                click: (e) => {
                  // console.log(e.target.options.data);  // will print trail name in console
                  onButtonClicked(trail.sportsPlaceId);
                },
                mouseover: (e) => {
                  // console.log(e.target.options.data);  // will print trail name in console
                  onMouseOver(trail.sportsPlaceId, "lipas");
                },
                mouseout: (e) => {
                  // console.log("Mouse out")
                  onMouseOut();
                }
              }}>
  
              <Tooltip>
                  {trail.name}
              </Tooltip>
            </Marker>
          )
        }
        </FeatureGroup>
        <ShowGeoJSON/>  
      </MapContainer>
      </div>
    );
  };

  export default ShowHikingMap;