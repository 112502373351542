import React from 'react'
import ReactDOM from 'react-dom';
import CloseButton from 'react-bootstrap/CloseButton';
import classes from './Modal.module.css'

const Backdrop = props => { 
    return <div className={classes.backdrop} onClick={props.onClose} />
};

const ModalOverlay = props => {
    return (
    <div className={classes.modal}>
        <CloseButton className="pull-right" onClick={props.onClose} />
        <div className={classes.content}>{props.children}</div>
    </div>
    )
};

const MyModal = props => {
    return <React.Fragment>
        {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, document.getElementById('overlays'))}
        {ReactDOM.createPortal(<ModalOverlay onClose={props.onClose}>{props.children}</ModalOverlay>, document.getElementById('overlays'))}
    </React.Fragment>
};

export default MyModal;