import React from 'react';
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';

const Caret = (props) => {


    if (!props.filterHidden) {
      return (
          <CaretLeftFill color="white" size={96}/>
      )}
    else {
        return (
            <CaretRightFill color="white" size={96} />
        )
    }
  }

export default Caret;