import L from 'leaflet';

// import icons
import marker_default from 'leaflet/dist/images/marker-icon.png';
import icon_trail from '../../images/patikka.png';
import icon_ski from '../../images/latu.png';

import icon_usertrail from '../../images/patikka-u.png';
import icon_usertrail_highlight from '../../images/patikka-u-highlight.png';

import icon_trail_highlight from '../../images/patikka-highlight.png';
import icon_ski_highlight from '../../images/latu-highlight.png';

import icon_sign from '../../images/kyltti.png';
import icon_sign_highlight from '../../images/kyltti-highlight.png';

import icon_parking from '../../images/parking.png';
import icon_parking_highlight from '../../images/parking-highlight.png';

import icon_start from '../../images/pin-icon-start.png';
import icon_end from '../../images/pin-icon-end.png';

import icon_fireplace from '../../images/leiri.png'

export const defaultIcon = new L.Icon({
  iconUrl: marker_default,
  iconRetinaUrl: marker_default,
  iconSize: [30, 40],
  iconAnchor: [10,30]
})

export const trailIcon = new L.Icon({
    iconUrl: icon_trail,
    iconRetinaUrl: icon_trail,
    iconSize: [30, 40],
    shadowSize: [50, 50],
    iconAnchor: [20,30],
    shadowAnchor: [4, 62],
    popupAnchor: [-3, -76],
})

export const userTrailIcon = new L.Icon({
  iconUrl: icon_usertrail,
  iconRetinaUrl: icon_usertrail,
  iconSize: [30, 40],
  shadowSize: [50, 50],
  iconAnchor: [20,30],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
})

export const trailIconHighlighted = new L.Icon({
  iconUrl: icon_trail_highlight,
  iconRetinaUrl: icon_trail_highlight,
  iconSize: [43, 50],
  shadowSize: [50, 50],
  iconAnchor: [27, 40],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
})

export const userTrailIconHighlighted = new L.Icon({
  iconUrl: icon_usertrail_highlight,
  iconRetinaUrl: icon_usertrail_highlight,
  iconSize: [43, 50],
  shadowSize: [50, 50],
  iconAnchor: [27, 40],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
})

export const skiIcon = new L.Icon({
  iconUrl: icon_ski,
  iconRetinaUrl: icon_ski,
  iconSize: [30, 40],
  shadowSize: [50, 50],
  iconAnchor: [20,30],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
})

export const skiIconHighlighted = new L.Icon({
  iconUrl: icon_ski_highlight,
  iconRetinaUrl: icon_ski_highlight,
  iconSize: [43, 50],
  shadowSize: [50, 50],
  iconAnchor: [27, 40],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
})

export const fireIcon = new L.Icon({
  iconUrl: icon_fireplace,
  iconRetinaUrl: icon_fireplace,
  iconSize: [30, 40],
  shadowSize: [50, 50],
  iconAnchor: [20,30],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
})

export const signIcon = new L.Icon({
  iconUrl: icon_sign,
  iconRetinaUrl: icon_sign,
  iconSize: [30, 40],
  shadowSize: [50, 50],
  iconAnchor: [20,30],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
})

export const signIconHighlighted = new L.Icon({
  iconUrl: icon_sign_highlight,
  iconRetinaUrl: icon_sign_highlight,
  iconSize: [43, 50],
  shadowSize: [50, 50],
  iconAnchor: [27, 40],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
})

export const parkingIcon = new L.Icon({
  iconUrl: icon_parking,
  iconRetinaUrl: icon_parking,
  iconSize: [30, 40],
  shadowSize: [50, 50],
  iconAnchor: [20,30],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
})

export const parkingIconHighlighted = new L.Icon({
  iconUrl: icon_parking_highlight,
  iconRetinaUrl: icon_parking_highlight,
  iconSize: [43, 50],
  shadowSize: [50, 50],
  iconAnchor: [27, 40],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
})


export const startIcon = L.icon({
    iconUrl: icon_start,      
    iconSize: [30, 40],    
    iconAnchor:   [25,35] // point of the icon which will correspond to marker's location
});

export const endIcon = L.icon({
  iconUrl: icon_end, 
  iconSize: [30, 40],         
  iconAnchor:   [25,35] // point of the icon which will correspond to marker's location
});