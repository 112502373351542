// This component reads the GPX file that user drags to dropzone
import React, { useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import { Icon } from 'semantic-ui-react';
import "./dropzone.css";

function GPXFileDropzone({handler}) {
  
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      // console.log("File: ", file);
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      
        const xmlData = reader.result;
        // setFile(xmlData);
        handler(xmlData);
        // console.log("xmlData: ", xmlData);
      }
      reader.readAsText(file)
    })
    
  }, [handler])


  const { getRootProps, getInputProps, isDragActive} = useDropzone({accept : {'application/gpx': ['.gpx']},  onDrop: onDrop, multiple: false});

  return (
    <div {...getRootProps({ className: "dropzone"})}>
      <input {...getInputProps()} />
      
      {isDragActive ? 
            <div className="dropzone-content">
              <p className="dropzone-text">
              <Icon size='large' name='download' />
              Pudota tiedosto tähän
              </p>
            </div> :
            <div className="dropzone-content">
              <p className="dropzone-text">
                <Icon link size='large' name='download' />
               Klikkaa tai raahaa GPX-tiedosto
               </p>
            </div>}
    </div>
  )
}

export default GPXFileDropzone;