import React, {useState}  from 'react';
import { Routes, Route,  HashRouter } from 'react-router-dom';
import './custom.css';
import { UserContext } from './components/user/user-context';
import TrailContainer from './components/trails/trailcontainer';
import MenuBar from './components/UI/menubar';
import GeoLocation from './components/location/geolocation';

function App() {

  const [signedInStatus, setSignedIn] = useState(false);
  const defaultCenter = {                       // this is used as center, if user location not found and no address selected
    location: { lat: 62.22, lng: 25.75 },
    address: 'Jyväskylä, Finland',
  };

  const [userAddress, setUserAddress] = useState(defaultCenter);
  
  const handlePositionChange = (newPosition) => {
    // console.log("New position: ", newPosition )
    setUserAddress(newPosition);
  }

  const handleSignInStatusChange = () => {
    setSignedIn(prevValue => !prevValue);
  }

  const ctxValue = {
    status: signedInStatus,
    onSignIn: handleSignInStatusChange
  }

  return ( 
    <UserContext.Provider value={ctxValue}>

    <HashRouter> 
       <GeoLocation defaultCenter = {defaultCenter} onPositionChange={handlePositionChange} />   
        <MenuBar /> 
        <Routes>
          <Route path="/" element={<TrailContainer userLocation={userAddress.location} />} />
          <Route path="Reitit" element={<TrailContainer userLocation={userAddress.location} />}/>
         </Routes>

    </HashRouter>
      
    </UserContext.Provider>
  )
}

export default App;
