import React, { useContext, useRef } from 'react';
import classes from './Login.module.css'
import Button from 'react-bootstrap/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import { UserContext } from '../user/user-context';
import axios from 'axios';

const Login = ({onSubmit}) => {
    const recaptcha = useRef();
    const { status, onSignIn } = useContext(UserContext);

    async function handleSignIn(event) {
      event.preventDefault();
      const token = recaptcha.current.getValue();
      if (!token) {
        alert("Vahvista että et ole robotti!");
      } else {
        // make form submission
        // ToDo: verification
        const baseurl = "https://xn--retkell-cxa.fi/api/recaptcha.php";
        axios.post(baseurl, {
          captchavalue: token
        }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          if (response.data.success) {
            onSignIn();
          }
          else alert("Vahvistus epäonnistui");
        })
        .catch(function (error) {
          console.log(error);
        });       
      }
      onSubmit();
    }

    return (
    <>
    <h2>KIRJAUDU</h2>
    <p>Kirjautumalla saat kaikki ominaisuudet käyttöösi.</p>
    
    <span className={classes.FormContainer}>
    <form>
      <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} /> 
      <Button onClick={handleSignIn} variant="primary">Kirjaudu</Button>  <Button onClick={onSubmit} variant="secondary">Peruuta</Button>
    </form>
    </span>
    </>
    )
}

export default Login;