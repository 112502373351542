// This component shows the navigation bar on the top of the home page.

import React, { useState, useContext } from "react";
import { Navbar, Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Compass, InfoCircleFill } from "react-bootstrap-icons";
import classes from "./menubar.module.css";
import Modal from "./Modal";
import Infoboksi from "./infoboksi";
import { UserContext } from "../user/user-context";
import Login from "./Login";
import UploadForm from "./upload";

export default function MenuBar(props) {
  const [showModal, changeModalValue] = useState(false);
  const { status, onSignIn } = useContext(UserContext);
  const [ModalContent, changeModalContent] = useState("login");

  const modalToggler = () => {
    changeModalValue((prevValue) => !prevValue);
  };

  function showInfo() {
    changeModalContent("info");
    modalToggler();
  }

  function showLogin() {
    changeModalContent("login");
    modalToggler();
  }

  function showUpload() {
    changeModalContent("upload");
    modalToggler();
  }

  function handleLogin() {
    modalToggler();
    // onSignIn();           // moved to Login -component
  }

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="primary"
      data-bs-theme="dark"
      variant="dark"
    >
      <Container fluid className={classes.container}>
        <Navbar.Brand className={classes.brand} href="#">
          <h2>
            {" "}
            <Compass className={classes.logo} color="white" />
            Retkellä
          </h2>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className={classes.navbar} id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="https://xn--retkell-cxa.fi/retket/">Blogi</Nav.Link>
            <Nav.Link onClick={showInfo}>Info</Nav.Link>
            {status && (
              <Nav.Link onClick={showUpload}>Lataa oma reitti</Nav.Link>
            )}
          </Nav>
          <Nav className={classes.login}>
            {status && <Navbar.Text>Olet kirjautunut sisään</Navbar.Text>}
            {!status && <Nav.Link onClick={showLogin}>Kirjaudu</Nav.Link>}
          </Nav>
        </Navbar.Collapse>
      </Container>

      {showModal && (
        <Modal onClose={modalToggler}>
          {ModalContent === "info" && <Infoboksi />}
          {ModalContent === "login" && <Login onSubmit={handleLogin} />}
          {ModalContent === "upload" && <UploadForm />}
        </Modal>
      )}
    </Navbar>
  );
}
