import { useState, useEffect } from 'react';
import React from 'react';
import GPXFileDropzone from '../UI/dropzone';
import UserTrail from '../traildetails/usertrail';
import './upload.css';
import 'gpxparser';
import { Row, Col, Container } from 'react-bootstrap';
import { storeTrail } from '../../http/uploaded';

const UploadForm = () => {

    const [gpxUploaded, gpxChange] = useState(false);
    const [file, setFile] = useState(null);
    const [saveStatus, setSaveStatus] = useState(null);
    const [gpxdetails, setGpxdetails] = useState({
        distance: 0, 
        time: 0, 
        elevation: 0, 
        trailname: "", 
        traildate: "",
        latitude: 0,
        longitude: 0,
        route: null
    });    

    let cssClass = '';

    useEffect(() => {
        let gpxParser = require('gpxparser');
        const gpx = new gpxParser(); //Create gpxParser Object
        if (file) {
          gpx.parse(file); //parse gpx file from string data
          if (gpx) {
            const trailData = gpx.tracks[0];                                          
            const date = gpx.metadata.time.slice(0, 10) // "2013-03-10";
            const geoJSON = gpx.toGeoJSON();
            setGpxdetails({
              distance: (trailData.distance.total/1000).toFixed(2), 
              time: '', 
              elevation: trailData.elevation.pos.toFixed(), 
              trailname: trailData.name, 
              traildate: date,
              latitude: trailData.points[0].lat,
              longitude: trailData.points[0].lon,
              route: JSON.stringify(geoJSON)
            })
          }
        }
            
          // const trailname = result.gpx.trk[0].name[0]; 
          // console.log("Trail name: ", trailname);
          //  trail_location = [result.gpx.trk[0].trkseg[0].trkpt[0].$.lat,  result.gpx.trk[0].trkseg[0].trkpt[0].$.lon];                                                    // get the trail name from the gpx data
          // console.log("Trail location: ", trail_location);                                            // upload the stringified geojson to the cloud
    
      }, [file])
      
    function showMap(content) {
        // console.log("file updated, content: ", content);
        if (content) {
            gpxChange(true);
            setFile(content);
        }
    }

    function neverMind() {
        gpxChange(false);
        setSaveStatus(null);
    }

    async function saveHandler() {
        try {
            // console.log("storing trail data, trailname: ", gpxdetails.trailname);
            const response = await storeTrail(          // save trail in database
                file, 
                gpxdetails);    
            // console.log("Savehandler got response status: ", response);
            setSaveStatus(response);                        

            cssClass = ':active';
            setTimeout(() => {
                setSaveStatus(null);
                cssClass = '';
            }, 5000);

        } catch(err) {
            console.log ("Storing failed, err: ", err);
        }
    }

    return <React.Fragment>

        {gpxUploaded ?
            <UserTrail  file={file} details={gpxdetails}></UserTrail> :
            <GPXFileDropzone handler={showMap}></GPXFileDropzone>
        }

        {gpxUploaded && 
            <Container fluid>
                <Row>
                    <Col><button className="HalfButton" onClick={neverMind}>Lataa uusi gpx-tiedosto</button></Col>
                    
                    <Col><button className="HalfButton" onClick={saveHandler}>Tallenna reitti</button></Col>
            </Row>

            { (saveStatus !== null) &&

                <Row>
                    <div className="savebox">
                    { saveStatus === false && <p className='save-fail'>Tallennus epäonnistui</p>}
                    { saveStatus === true && <p className='save-success'>Tallennus onnistui</p>}
                    </div>
                </Row>
            } 
               

            </Container>
        }
    </React.Fragment>
           
};

export default UploadForm;