import {trailIcon, skiIcon, trailIconHighlighted, skiIconHighlighted, userTrailIcon, userTrailIconHighlighted, defaultIcon, fireIcon, signIcon, signIconHighlighted} from './mapicons';

export const markerIcon = (trail, highlighted) => {
    let markerIcon = defaultIcon;

    switch(trail.type.typeCode) {
      case 4405 : 
        if (trail.sportsPlaceId === highlighted)
          markerIcon = trailIconHighlighted;
        else
          markerIcon = trailIcon;
        break;
      case 4402:
        if (trail.sportsPlaceId === highlighted)
          markerIcon = skiIconHighlighted;
        else
          markerIcon = skiIcon;
        break;
      case 206:
          markerIcon = fireIcon;
        break;
      case 301:
          markerIcon = fireIcon;
        break;
      case 207:
        if (trail.sportsPlaceId === highlighted)
          markerIcon = signIconHighlighted;
        else
          markerIcon = signIcon;
    }
    return markerIcon;
  }

  export const userTrailMarkerIcon = (trail, highlighted) => {
    if (trail.id === highlighted) return (userTrailIconHighlighted)
    else return(userTrailIcon)
  }
