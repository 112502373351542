import "./listtrails.css";

export default function ListTrails({trails, usertrails, chooseTrail, chooseUserTrail, highlightTrail, highlightUserTrail}) {

    let nroftrails = trails ? trails.length : 0;
    let nrofusertrails = usertrails ? usertrails.length : 0;
    let totalnroftrails = nroftrails + nrofusertrails;
  
    return (
    <div>
      {(totalnroftrails === 0) ? <div> Ei hakutuloksia</div>:
        <div>
          Hakutulokset:
          <div className="traillist">
            { trails && trails.map((trail) => (
                <button key={trail.sportsPlaceId} onClick={() => chooseTrail(trail.sportsPlaceId)} onMouseOver={() => highlightTrail(trail.sportsPlaceId)} >{trail.name}</button>
              ))
            }
            { usertrails && usertrails.map((trail, i) => (
                <button key={i} onClick={() => chooseUserTrail(trail.id)} onMouseOver={() => highlightUserTrail(trail.id)}>{trail.trailname}</button>
              ))
            }
          </div>
        </div>
      }
    </div>
    );
}
