import React, {useState, useEffect, Fragment, useContext, useRef  } from 'react'
import { Checkbox, Form } from 'semantic-ui-react'
import './filter.css'
import { ArrowCounterclockwise } from 'react-bootstrap-icons';
import { XLg } from 'react-bootstrap-icons';
import { UserContext } from '../user/user-context';

export default function FilterForm({onSelectionChange, onSearchButtonClicked, onResetButtonClicked}) {
  const [routesChecked, setRoutesChecked] = useState(true);
  const [trailsChecked, setTrailsChecked] = useState(false);
  const [userRoutesChecked, setUserRoutesChecked] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [delay, setDelay] = useState(null);
  const { status, onSignIn } = useContext(UserContext);

  useEffect(() => {

    if (!inputValue) return;

    if (delay) {
      clearTimeout(delay);
    }

    // delay timer is reset every time the user presses a key. If the delay after previous key is more than 0,5 s, the search is initiated
    const newDelay = setTimeout(() => {
      if (inputValue.length > 2) {
        onSearchButtonClicked(inputValue);
      }
    }, 1000)
    
    setDelay(newDelay);     
   

  }, [inputValue])

  const handleChange = (e, { value }) => {

    if (value === '0') {
      onSelectionChange([!routesChecked, trailsChecked, userRoutesChecked])
      setRoutesChecked(prevRoutesChecked => !prevRoutesChecked);
    }
    if (value === '1') {
      onSelectionChange([routesChecked, !trailsChecked, userRoutesChecked]);
      setTrailsChecked(prevTrailsChecked => !prevTrailsChecked);
    }
    if (value === '2') {
      onSelectionChange([routesChecked, trailsChecked, !userRoutesChecked]);
      setUserRoutesChecked(prevRoutesChecked => !prevRoutesChecked);
    }
  };

  const handleSubmit = (e) => e.preventDefault()

  function resetHandler(event) {
    event.preventDefault();
    setInputValue('');
    onResetButtonClicked();
  }



  const handleInputChange = (e) => {
    let newValue = e.target.value;
    setInputValue(newValue);    
}

  return (
    <Fragment>
      <div className="filter">
        <Form>
          { status &&
              <Form.Field
                control={Checkbox}
                label='Ladatut reitit' 
                value='2'
                checked={userRoutesChecked}
                onChange={handleChange}
            />
            }
          <p><b>Julkiset liikuntapaikat:</b></p>
          <Form.Field
            control={Checkbox}
            label='Retkeilyreitit'
            value='0'
            checked={routesChecked}
            onChange={handleChange}
          />
          <Form.Field
              control={Checkbox}
              label='Ladut' 
              value='1'
              checked={trailsChecked}
              onChange={handleChange}
            />
            
        </Form>
      </div>
      <div> 
      <Form autoComplete="off" onSubmit={handleSubmit} onReset={resetHandler} >
          
          <label>Hae nimellä:</label>
          <Form.Field/>
          <Form.Field id="searchform">
            <input type="text" onChange={handleInputChange} value= {inputValue} name="trailsearch"  placeholder='Kirjoita tähän' id="trailsearch" autoComplete="trailsearch"   />
            <button className="resetbutton" type = "reset"  ><XLg /></button>
          </Form.Field>
        <Form.Field/>
      </Form>
      </div>
    </Fragment>
  )
}

