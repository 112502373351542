// Shows the public trails (open data service provided by Lipas)
import React, { useState, useEffect } from 'react';
import { Row, Col, Container  } from 'react-bootstrap';
import { Header, Segment } from 'semantic-ui-react';

import FilterForm from './filter';
import TrailDetails from '../traildetails/lipastrail';
import  './hikingmapcontainer.css';
import MyModal from '../UI/Modal';
import Caret from '../UI/caret';
import ListTrails from '../traildetails/listtrails';
import { fetchData, searchTrails } from '../../http/lipashaut';
import { getUserTrails } from '../../http/uploaded';
import UserTrail from '../traildetails/usertrail';
import HikingMapContainer from './hikingmapcontainer';

export default function TrailContainer({ userLocation }) {

    const [trails, setTrails] = useState([]);                                       // for storing trail list fetched from lipas based on filter
    const [userTrails, setUserTrails] = useState([]);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState([true, false]);                          // initial value for filters
    const [currentcenter, setCurrentCenter] = useState({lat: 62.23, lng: 26.02});           //current map center
    const [currentTrail, setCurrentTrail] = useState(null);                          // ID of selected trail
    const [currentUserTrail, setCurrentUserTrail] = useState(null); 
    const [highlightedTrail, setHighLight] = useState(null);
    const [highlightedUserTrail, setHighLightUser] = useState(null);
    const [filterHidden, hideFilter ] = useState(false);
    const [searchResultsVisible, showSearchResults] = useState(false);
 
    async function getAllTrails(center) {

        if (filters[2]){  
          try {                                                   // get user trails
          const alluserTrails = await getUserTrails();
          setUserTrails(alluserTrails);
          // console.log("UserTrails in getAllTrails: ", alluserTrails);
          } catch(err) {
            console.log("Error in fetching user trails: ", err);
            setUserTrails(null);
          }
        }
        else setUserTrails(null);

        if (filters[0] || filters[1]){
          try {           
            const allTrails = await fetchData(filters, center);       // fetch trails from Lipas
            setTrails(allTrails);
          } catch(err) {
            console.log("Error in fetching trails: ", err);
            setTrails(null);
          }
        } else {                                      // filters are empty -> trail list is cleared
          setTrails(null);
        }
    }

    useEffect(() => {
      // initialize current center
      setCurrentCenter(userLocation);
    }, [userLocation])

    useEffect(() => {
      // fetch public trails from server
      // don't fetch new trails if map center is moved and search results are visible
      if (!searchResultsVisible) getAllTrails(currentcenter);

      return() => {
        // console.log ("clearing")
      }
    }, [filters, currentcenter, searchResultsVisible])

    const handleUserTrailClick = (id) => {
        const selectedTrail = userTrails.find(item => item.id === id);
        setCurrentUserTrail(selectedTrail);
    }

    const filterToggleHandler = () => {
        hideFilter(prevFilter => !prevFilter);
    };

    const modalCloser = () => {
       setCurrentTrail(null);
       setCurrentUserTrail(null);
     }

     async function handleSearchButtonClick (searchString){

        // ToDo: add search to user trails
        if ((filters[2]) || ((filters[0] === 0) && (filters[1] === 0) (filters[2] === 0))) {
          // Either user trails selected or no filters selected -> search for user trails
          try {
            const matchingUserTrails = await getUserTrails(searchString);
            setUserTrails(matchingUserTrails);
          }
          catch(err) {
            console.log("Error in searching user trails: ", err);
            setUserTrails(null);
          }
        }
         try {    
            const stringToPrint = '*' + searchString + '*';     
            const allTrails = await searchTrails(stringToPrint, filters);       // fetch trails from Lipas
            setTrails(allTrails);
          } 
          catch(err) {
            console.log("Error in searching trails: ", err);
            setTrails(null);
          }
        
          showSearchResults(true);
      }

    const handleResetButtonClick = () => {
        // console.log("Reset button clicked");
        showSearchResults(false);
        getAllTrails(currentcenter);
    }

    const tooltip = filterHidden ? "Näytä suodattimet" : "Piilota suodattimet";

    return (
      <Container fluid>
        <Row  >
        {!filterHidden &&
        <Col xs={12} ms={2} className='filter'  >
            <Segment raised>
              <Header>Hae reittejä</Header>
              <FilterForm 
                onSelectionChange = {setFilters} 
                onSearchButtonClicked={handleSearchButtonClick} 
                onResetButtonClicked={handleResetButtonClick}
              />
              {searchResultsVisible && 
                <ListTrails 
                    trails={trails}
                    usertrails={userTrails}
                    chooseTrail={setCurrentTrail}
                    chooseUserTrail={handleUserTrailClick}
                    highlightTrail={setHighLight}
                    highlightUserTrail={setHighLightUser}>
                </ListTrails>}
            </Segment> 
        </Col>}
          <Col  className='toggler bg-secondary' title={tooltip}  xs={12} sm={1} onClick={filterToggleHandler}>
            <Caret filterHidden = {filterHidden} /> <br/>
            <Caret filterHidden = {filterHidden} /> <br/>
            <Caret filterHidden = {filterHidden} /> 
          </Col>
          <Col sm xs={12} >
            
            <HikingMapContainer 
              center = {userLocation} 
              trails = {trails} 
              usertrails = {userTrails}
              fitbounds = {searchResultsVisible}
              highlighted = {highlightedTrail}
              usertrailhighlighted = {highlightedUserTrail}
              onTrailSelect = {setCurrentTrail} 
              onUserTrailSelect = {handleUserTrailClick}
              onCenterChange = {setCurrentCenter}
            />

            {(currentTrail !== null) && <MyModal onClose={modalCloser} ><TrailDetails trailID = {currentTrail}/></MyModal>}
            {(currentUserTrail !== null) && <MyModal onClose={modalCloser} ><UserTrail details = {currentUserTrail}/></MyModal>}
          </Col>
        </Row>
      </Container>
    )
}