
export async function storeTrail(file, details) {

  // console.log("Storing", JSON.stringify(details));
  const response = await fetch('https://retkella.net/api/store.php', {
    method: 'PUT',
        body: JSON.stringify(details),
        headers: {
            'Content-Type': 'application/json'
        }
  });
  
  // console.log("response: ", response.ok);
  return response.ok;
}

export async function getUserTrails(searchString) {
  const baseurl ='https://retkellä.fi/api/usertrails.php';
  let apiurl = baseurl;
  // const apiurl ='https://retkellä.fi/api/searchusertrail.php';  // for testing the search feature
  if (searchString) {
    apiurl = apiurl + '?searchfor=' + searchString;
    // console.log("Searching user trail with url ", apiurl);
  }

  const response = await fetch(apiurl);
  if (!response.ok) {
    console.log('Fetching failed');
    return response.ok;
  }
 //  console.log("Response: ", response);
 
    const resData = await response.json();
    return resData.data;
  
  
}
