import React, { useState, useEffect } from 'react';
import { fetchTrailDetails } from '../../http/lipashaut';
import ShowHikingMap from '../maps/showhikingmap';

export default function HikingMapContainer({center, trails, usertrails, highlighted, onTrailSelect, onUserTrailSelect, fitbounds, onCenterChange}) {
  
    const [highlightedTrail, setHighLight] = useState(null);
    const [trailType, setTrailType] = useState('4405');              // trail marker to be added to map
    const [geoJSON, setJSON] = useState(null);                       // geoJSON to be added to the map
   
    async function onMouseOver (id, trailtype) {
        // this function is called when user has moved the mouse on the trail marker on the map
        if (trailtype === "lipas") {    
            try {
                const traildetails = await fetchTrailDetails(id);
                setTrailType(traildetails.trailtype);
                setJSON(traildetails.trailjson);
              } catch(err) {
                console.log("Could not fetch trail data")
              }
              setHighLight(id); 
        }
        else {                              // user trail
            const selectedTrail = usertrails.find(item => item.id === id);
            const gjson = JSON.parse(selectedTrail.geojson);
            setJSON(gjson);
            setTrailType ('UserTrail');
        }
    }
  
    const onMouseOut = () => {
        // this function is called when user has moved the mouse on the trail marker on the map
        setHighLight(null); 
    }
   
    return (
        <ShowHikingMap 
                    center = {center} 
                    trails = {trails} 
                    usertrails = {usertrails}
                    highlighted = {highlightedTrail || highlighted}
                    fitbounds = {fitbounds}
                    trailType = {trailType} 
                    gJSON = {geoJSON} 
                    onButtonClicked = {onTrailSelect} 
                    onMouseOver = {onMouseOver} 
                    onMouseOut = {onMouseOut} 
                    onUserTrailClicked={onUserTrailSelect}
                    onCenterMoved = {onCenterChange} />
    )
}